<template>
  <div>
    <Header />
    <br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br />
    <div class="container">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-xl-6">
                <div class="product-detail">
                  <div class="tab-content" id="v-pills-tabContent">
                    <div
                      class="tab-pane fade active show"
                      id="product-4"
                      role="tabpanel"
                    >
                      <div class="product-img">
                        <img
                          src="https://www.hanwha-pm.com/upload/product/20210517/143638cBGl.jpg"
                          alt="img-4"
                          class="img-fluid mx-auto d-block"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <!-- end product img -->
              </div>
              <div class="col-xl-6">
                <div class="mt-4">
                  <h1 class="font-size-20 mb-3">
                    <strong> Product description: </strong>
                  </h1>
                  <h2>
                    Provides optimum solution and convenience for placing
                    various odd-shape components and Shield Cans etc.
                  </h2>
                  <br />
                  <ul class="container">
                    <li>
                      <h5>
                        Actual productivity is highest among machines of the
                        same class
                      </h5>
                    </li>
                    <li>
                      <h5>Optimized to high quality production</h5>
                    </li>
                    <li>
                      <h5>
                        Unmanned, non-stop and zero defect using the Smart
                        Factory S/W Solution
                      </h5>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <!-- end row -->
            <br /><br /><br /><br /><br /><br /><br />
            <h1><strong> Specifications </strong></h1>
            <br /><br />
            <div class="row container" style="font-size: 18px">
              <div class="container-fluid">
                <div class="table-responsive">
                  <table class="table table-nowrap mb-0">
                    <tbody>
                      <tr>
                        <th scope="row" style="width: 400px">Alignment</th>
                        <td style="font-size: 12px">Fix Camera</td>
                      </tr>
                      <tr>
                        <th scope="row">Number of Spindles</th>
                        <td style="font-size: 12px">3 Spindles x 1 Gantry</td>
                      </tr>
                      <tr>
                        <th scope="row">Placement Speed</th>
                        <td style="font-size: 12px">11,000 CPH</td>
                      </tr>
                      <tr>
                        <th scope="row">Placement Accuracy</th>
                        <td style="font-size: 12px">
                          0402 ~ □ 55 mm, ~ L74 mm x W55 mm (Size)
                        </td>
                      </tr>
                      <tr>
                        <th scope="row">Component Range</th>
                        <td style="font-size: 12px">
                          ±40 μm @ Cpk ≥ 1.0 (Chip) / ±30 μm @ Cpk ≥ 1.0
                          (Lead/BGA)
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>

                <br /><br />
                <h1><strong>Features</strong></h1>
                <br /><br />

                <div class="row">
                  <div class="col-xl-6">
                    <div class="product-detail">
                      <div class="tab-content" id="v-pills-tabContent">
                        <div
                          class="tab-pane fade active show"
                          id="product-4"
                          role="tabpanel"
                        >
                          <div class="product-img">
                            <img
                              src="https://www.hanwha-pm.com/en/resource/images/product/ico_features_smt_1.jpg"
                              alt="img-4"
                              class="img-fluid mx-auto d-block"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <!-- end product img -->
                  </div>
                  <div class="col-xl-6">
                    <div class="mt-4">
                      <h1 class="font-size-20 mb-3" style="color: #f60">
                        <strong> High Productivity</strong>
                      </h1>
                      <h3>
                        <strong>Flexible Applicability to PCBs</strong>
                      </h3>
                      <p>
                        Possible to apply single lane or dual lane depending on
                        user’s operation environment and perform mixed
                        production of different components by applying the dual
                        lane.
                      </p>
                    </div>
                  </div>
                </div>
                <!-- end row -->
                <div class="row">
                  <div class="col-xl-6">
                    <div class="product-detail">
                      <div class="tab-content" id="v-pills-tabContent">
                        <div
                          class="tab-pane fade active show"
                          id="product-4"
                          role="tabpanel"
                        >
                          <div class="product-img">
                            <img
                              src="https://www.hanwha-pm.com/en/resource/images/product/ico_features_smt_2.jpg"
                              alt="img-4"
                              class="img-fluid mx-auto d-block"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <!-- end product img -->
                  </div>
                  <div class="col-xl-6">
                    <div class="mt-4">
                      <h1 class="font-size-20 mb-3" style="color: #f60">
                        <strong> High Reliability </strong>
                      </h1>
                      <h3>
                        <strong>
                          Inspection of Defective Component Placement
                          (PBI)</strong
                        >
                      </h3>
                      <p>
                        Inspects the state of the Shield-Can component placement
                        to prevent defective placement in advance.
                      </p>
                    </div>
                  </div>
                </div>
                <!-- end row -->
                <div class="row">
                  <div class="col-xl-6">
                    <div class="product-detail">
                      <div class="tab-content" id="v-pills-tabContent">
                        <div
                          class="tab-pane fade active show"
                          id="product-4"
                          role="tabpanel"
                        >
                          <div class="product-img">
                            <img
                              src="https://www.hanwha-pm.com/en/resource/images/product/ico_features_smt_3.jpg"
                              alt="img-4"
                              class="img-fluid mx-auto d-block"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <!-- end product img -->
                  </div>
                  <div class="col-xl-6">
                    <div class="mt-4">
                      <h1 class="font-size-20 mb-3" style="color: #f60">
                        <strong> Flexible Production</strong>
                      </h1>
                      <h3><strong> Back Light Recognition</strong></h3>
                      <p>
                        Accurately recognizes irregularly reflecting and
                        semi-transparent components, such as a shield can.
                      </p>
                    </div>
                  </div>
                </div>
                <!-- end row -->
                <div class="row">
                  <div class="col-xl-6">
                    <div class="product-detail">
                      <div class="tab-content" id="v-pills-tabContent">
                        <div
                          class="tab-pane fade active show"
                          id="product-4"
                          role="tabpanel"
                        >
                          <div class="product-img">
                            <img
                              src="https://www.hanwha-pm.com/en/resource/images/product/ico_features_smt_7.jpg"
                              alt="img-4"
                              class="img-fluid mx-auto d-block"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <!-- end product img -->
                  </div>
                  <div class="col-xl-6">
                    <div class="mt-4">
                      <h1 class="font-size-20 mb-3" style="color: #f60">
                        <strong> Easy Operation </strong>
                      </h1>
                      <h3>
                        <strong>NPE (New Part Editor)</strong>
                      </h3>
                      <p>
                        A Drag & Drop based component registration system, it
                        performs automatic component recognition and rotates,
                        allowing registration of component information by simply
                        clicking the mouse
                      </p>
                    </div>
                  </div>
                </div>
                <!-- end row -->
              </div>
            </div>
          </div>
        </div>
        <!-- end card -->
      </div>
    </div>
    <Footer />
  </div>
</template>
<script>
import Header from "../../../../components/Header.vue";

import Footer from "../../../../components/Footer.vue";

/**
 * Dashboard component
 */
export default {
  components: {
    Header,

    Footer,
  },
};
</script>